import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import FavoriteHeartIcon from '../../../../../assets/icons/FavoriteHeartIcon';

import DeletePlacementMenuButton from './DeletePlacementMenuButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #E0E0E0',
  },
  propText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#7A7A7A',
  },
});

const ReservedEmployeeRosterCard = ({
  placement,
  onManageCandidate,
  onDeleteFromOne,
  onDeleteFromAll,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" flex={7}>
        <Avatar src={placement?.employeeProfilePicture} alt={placement.employeeName} />
        <Typography style={{ marginLeft: '10px', fontSize: '24px' }}>
          {placement?.employeeName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '24px', padding: '0 10px', flex: 3.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.favorite')}
          </Typography>
          <IconButton sx={{ width: '36px' }}>
            <FavoriteHeartIcon isFavorite={placement?.isFavorite} width={36} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.reliability')}
          </Typography>
          <Typography sx={{ display: 'flex', fontSize: '18px' }}>
            {placement?.reliabilityScore ? `${placement.reliabilityScore * 100}%` : '--'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', flex: 0.5, gap: '12px' }}>
        <IconButton onClick={onManageCandidate}>
          <MoreHorizIcon />
        </IconButton>
        <DeletePlacementMenuButton
          onDeleteFromOne={onDeleteFromOne}
          onDeleteFromAll={onDeleteFromAll}
        />
      </Box>
    </Box>
  );
};

ReservedEmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    id: PropTypes.string,
    employeeName: PropTypes.string,
    employeeProfilePicture: PropTypes.string,
    isFavorite: PropTypes.bool,
    reliabilityScore: PropTypes.number,
    status: PropTypes.string,
  }),
  onManageCandidate: PropTypes.func,
  onDeleteFromOne: PropTypes.func,
  onDeleteFromAll: PropTypes.func,
};

export default ReservedEmployeeRosterCard;
