import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import theme from '../../../../theme';
import { epochToTimeInReadableFormat } from '../../../../utils';
import { getHours, getTotalHours } from '../../helperFunctions';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    boxShadow: 'none',
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      justifyContent: 'space-between',
      '& .header': {
        display: 'flex',
        '& .avatar': {
          marginRight: '10px',
          width: '34px',
          height: '34px',
        },
        '& .employee': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .name': {
            display: 'flex',
            fontSize: '16px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.employeeName,
          },
          '& .checkInStatus': {
            display: 'flex',
            fontWeight: '300',
            fontSize: '14px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.checkInStatus,
          },
        },
      },
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingTop: '8px',
    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      '& .text': {
        flex: 1,
        textAlign: 'center',
        fontSize: '10px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        ...theme.jobOrders.detail.roster.employeeRosterCard.headerText,
      },
    },
    '& .punches': {
      display: 'flex',
      '& .time': {
        flex: 1,
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '24px',
        ...theme.jobOrders.detail.roster.employeeRosterCard.punchTime,
      },
    },
    '& .total': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .text': {
        textAlign: 'center',
        fontFamily: 'Barlow',
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '30px',
      },
    },
  },
});

const EmployeeRosterCard = ({ placement }) => {
  const classes = useStyles();

  const punches = placement?.timecard?.current?.punches;

  const CheckInStatuses = {
    PRESENT: 'present',
    SENT_HOME: 'sent-home',
    NO_SHOW: 'no-show',
  };
  const checkInStatus = placement?.timecard?.checkIn?.status;
  const checkInStatusText =
    (checkInStatus === CheckInStatuses.NO_SHOW && LanguageConverter('jobOrder.markedAsNoShow')) ||
    (checkInStatus === CheckInStatuses.SENT_HOME && LanguageConverter('jobOrder.markedAsSentHome'));

  return (
    <Card className={classes.card}>
      <CardContent className="content">
        <div className="header">
          <Avatar src={placement?.profilePic} alt={placement?.candidateName} className="avatar" />
          <div className="employee">
            <Typography variant="h6" className="name">
              {placement?.candidateName}
            </Typography>
            <Typography variant="body2" className="checkInStatus">
              {checkInStatusText}
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardContent className={classes.details}>
        <Box sx={{ border: 1, borderRadius: '5px' }}>
          <Box className="header" sx={{ borderBottom: 1 }}>
            <Typography className="text" sx={{ textAlign: 'center' }}>
              {LanguageConverter('buttonText.clockIn')}{' '}
            </Typography>
            <Typography className="text">{LanguageConverter('buttonText.clockOut')}</Typography>
            <Typography className="text"> {LanguageConverter('jobOrder.hours')} </Typography>
            <Typography className="text"> {LanguageConverter('jobOrder.totalHours')} </Typography>
          </Box>
          <Box sx={{ display: 'flex', direction: 'row' }}>
            <Box sx={{ width: '250px' }}>
              {punches?.length > 0 ? (
                punches?.map((punch) => (
                  <Box className="punches">
                    <Typography className="time">
                      {punch?.in?.stamp ? epochToTimeInReadableFormat(punch?.in?.stamp) : '--'}
                    </Typography>
                    <Typography className="time" sx={{ borderLeft: 1 }}>
                      {punch?.out?.stamp ? epochToTimeInReadableFormat(punch?.out?.stamp) : '--'}
                    </Typography>
                    <Typography className="time" sx={{ borderLeft: 1, borderRight: 1 }}>
                      {punch?.out?.stamp ? getHours(punch?.in?.stamp, punch?.out?.stamp) : '0.00'}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box className="punches">
                  <Typography className="time">0.00 AM</Typography>
                  <Typography className="time" sx={{ borderLeft: 1 }}>
                    0.00 AM
                  </Typography>
                  <Typography className="time" sx={{ borderLeft: 1, borderRight: 1 }}>
                    0.00
                  </Typography>
                </Box>
              )}
            </Box>
            <Box className="total" sx={{ width: '85px' }}>
              <Typography className="text">
                {getTotalHours(placement?.timecard?.current?.totalTime)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

EmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    _id: PropTypes.string,
    checkInStatus: PropTypes.string,
    candidateName: PropTypes.string,
    timecard: PropTypes.shape({
      current: PropTypes.shape({
        punches: PropTypes.arrayOf(PropTypes.shape({})),
        totalTime: PropTypes.number,
      }),
      checkIn: PropTypes.shape({ status: PropTypes.string }),
      status: PropTypes.string,
    }),
    start: PropTypes.number,
    end: PropTypes.number,
    profilePic: PropTypes.string,
    state: PropTypes.shape({ userName: PropTypes.string }),
  }),
};

export default EmployeeRosterCard;
