import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ACTION_BLUE } from '../../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';

const menuItemStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' };

const PendingStatusMenuButton = ({
  status,
  revokeOrAcceptText,
  resendOrRejectText,
  onRevokeOrAcceptInvite,
  onResendOrRejectInvite,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDeleteOne = useCallback(() => {
    onRevokeOrAcceptInvite();
    handleClose();
  }, [onRevokeOrAcceptInvite]);

  const handleOnDeleteAll = useCallback(() => {
    onResendOrRejectInvite();
    handleClose();
  }, [onResendOrRejectInvite]);

  return (
    <Box sx={{ alignSelf: 'center' }}>
      <Typography
        fontSize="18px"
        color={ACTION_BLUE.INDIGO_BLUE}
        fontFamily={PRIMARY_FONT[500]}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }}>
          {status}
          <ArrowDropDownIcon />
        </Box>
      </Typography>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleOnDeleteOne} sx={menuItemStyle}>
          <Typography>{revokeOrAcceptText}</Typography>
        </MenuItem>
        <MenuItem onClick={handleOnDeleteAll} sx={menuItemStyle}>
          <Typography>{resendOrRejectText}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

PendingStatusMenuButton.propTypes = {
  status: PropTypes.string,
  revokeOrAcceptText: PropTypes.string,
  resendOrRejectText: PropTypes.string,
  onRevokeOrAcceptInvite: PropTypes.func,
  onResendOrRejectInvite: PropTypes.func,
};

export default PendingStatusMenuButton;
