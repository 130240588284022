import theme from '../../../theme';

export const rootGridWidthAndHeightSx = (largerThanMediumScreen) => ({
  minHeight: '386px',
  width: largerThanMediumScreen ? '828px' : '277px',
});

export const selectionBoxStyling = {
  border: '1px solid',
  height: '386px',

  borderRadius: '5px',
  borderColor: theme?.components?.employeeInviteSelection?.palette?.light?.borderColor,
  overflow: 'auto',
  padding: theme.spacing(1, 1.5, 2, 1.5),
};

export const selectionTextStyling = {
  fontFamily: theme?.components?.employeeInviteSelection?.typography?.selectionTextFontFamily,
  color: theme?.components?.employeeInviteSelection?.palette?.light?.selectionTextFontColor,
  lineHeight: theme?.components?.employeeInviteSelection?.typography?.selectionTextFontLineHeight,
  fontSize: theme?.components?.employeeInviteSelection?.typography?.selectionTextFontSize,
  margin: theme.spacing(1, 0, 1, 0),
};

export const selectionTitleTextStyling = {
  fontFamily: theme?.components?.employeeInviteSelection?.typography?.selectionTitleTextFontFamily,
  color: theme?.components?.employeeInviteSelection?.palette?.light?.selectionTitleTextFontColor,
  lineHeight:
    theme?.components?.employeeInviteSelection?.typography?.selectionTitleTextFontLineHeight,
  fontSize: theme?.components?.employeeInviteSelection?.typography?.selectionTitleTextFontSize,
  margin: theme.spacing(1, 0, 1, 0),
};
export const rosterFillSubTitleStyling = {
  fontFamily:
    theme?.components?.employeeInviteSelection?.typography?.rosterFillSubTitleTextFontFontFamily,
  color:
    theme?.components?.employeeInviteSelection?.palette?.light?.rosterFillSubTitleTextFontColor,
  lineHeight:
    theme?.components?.employeeInviteSelection?.typography?.rosterFillSubTitleTextFontLineHeight,
  fontSize:
    theme?.components?.employeeInviteSelection?.typography?.rosterFillSubTitleTextFontFontSize,
};
export const invitedWorkerAmountStyling = {
  fontFamily:
    theme?.components?.employeeInviteSelection?.typography?.invitedWorkerAmountTextFontFontFamily,
  color:
    theme?.components?.employeeInviteSelection?.palette?.light
      ?.invitedWorkerAmountTextFontFontColor,
  lineHeight:
    theme?.components?.employeeInviteSelection?.typography
      ?.invitedWorkerAmountTextTextFontLineHeight,
  fontSize:
    theme?.components?.employeeInviteSelection?.typography?.invitedWorkerAmountTextFontFontSize,
  textAlign: 'center',
};
export const invitedWorkerAmountBackgroundGridStyling = {
  height: '50px',
  width: '50px',

  backgroundColor:
    theme?.components?.employeeInviteSelection?.palette?.light?.invitedWorkerAmountBackgroundColor,
  justifyContent: 'center',
  alignItems: 'center',
};

export const scrollBarStyles = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme?.components?.scrollBar?.altThumbColorGray,
    borderRadius: '50px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme?.components?.scrollBar?.thumbHover,
  },
};

export const buttonStyles = {
  height: '40px',
  width: '191px',
  borderRadius: '5px',
  background: theme?.components?.employeeInviteSelection?.palette?.light?.buttonBackGroundColor,
};

export const errorTextStyling = {
  fontFamily: theme?.components?.employeeInviteSelection?.typography?.errorFontFamily,
  color: theme?.components?.employeeInviteSelection?.palette?.light?.errorFontColor,
};
