import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FormSectionHeader = ({ messageKey }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Typography
      sx={{
        color: theme?.jobOrders?.createPage?.sectionHeader?.fontColor,
        fontSize: theme?.jobOrders?.createPage?.sectionHeader?.fontSize,
        fontFamily: theme?.jobOrders?.createPage?.sectionHeader?.font,
      }}
    >
      {intl.formatMessage({ id: messageKey })}
    </Typography>
  );
};

FormSectionHeader.propTypes = {
  messageKey: PropTypes.string,
};

export default FormSectionHeader;
