import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { handleOverPlacement } from '../../helperFunctions';

const useStyles = makeStyles({
  fillRatioWrapper: { width: '250px', display: 'flex', alignItems: 'center' },
  fillRatioInner: { minWidth: 35 },
  fillRatioText: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: '5px',
  },
  fillRatioProgressWrap: { width: '100%' },
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 20,
  border: '1px solid black',
  marginLeft: '5px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'green',
  },
}));

const ActiveAndUpcomingJobMetrics = ({ jobOrder }) => {
  const classes = useStyles();

  const numOfPendingInvites = jobOrder.placements.filter(
    (e) => e.state.status === 'pendingEmployeeAcceptance'
  ).length;

  return (
    <>
      <Box className="block">
        <Box className="title"> {LanguageConverter('jobOrder.fillRate')} </Box>
        <Box className={classes.fillRatioWrapper}>
          <Box className={classes.fillRatioInner}>
            <Typography className={classes.fillRatioText}>{`${handleOverPlacement(
              jobOrder?.fillRatio
            )}%`}</Typography>
          </Box>
          <Box className={classes.fillRatioProgressWrap}>
            <BorderLinearProgress
              variant="determinate"
              value={handleOverPlacement(jobOrder?.fillRatio)}
            />
          </Box>
        </Box>
      </Box>
      <Box className="block">
        <Box className="title">
          <Box className="twoColumn">
            <Box> {LanguageConverter('roster.form.openings')} </Box>
            <Box> {LanguageConverter('roster.form.placements')} </Box>
            <Box> {LanguageConverter('roster.form.pendingInvites')} </Box>
          </Box>
        </Box>
        <Box className="data">
          <Box className="twoColumn staffing">
            <Box> {jobOrder.numOpenings} </Box>
            <Box> {jobOrder.placementsCount} </Box>
            <Box>{numOfPendingInvites}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

ActiveAndUpcomingJobMetrics.propTypes = {
  jobOrder: PropTypes.shape({
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ActiveAndUpcomingJobMetrics;
