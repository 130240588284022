import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import FavoriteHeartIcon from '../../../../../assets/icons/FavoriteHeartIcon';

import PendingStatusMenuButton from './PendingStatusMenuButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #E0E0E0',
  },
  propText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#7A7A7A',
  },
});

const PendingEmployeeRosterCard = ({
  placement,
  onManageCandidate,
  isInvite,
  onRevokeOrAcceptInvite,
  onResendOrRejectInvite,
}) => {
  const classes = useStyles();
  const status = isInvite
    ? LanguageConverter('jobOrder.roster.status.invitePending')
    : LanguageConverter('jobOrder.roster.status.applications');

  const revokeOrAcceptText = isInvite
    ? LanguageConverter('jobOrder.roster.status.revokeInvite')
    : LanguageConverter('jobOrder.roster.status.accept');

  const resendOrRejectText = isInvite
    ? LanguageConverter('jobOrder.roster.status.resend')
    : LanguageConverter('jobOrder.roster.status.decline');

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" flex={7}>
        <Avatar src={placement?.employeeProfilePicture} alt={placement?.employeeName} />
        <Typography style={{ marginLeft: '10px', fontSize: '24px' }}>
          {placement?.employeeName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '24px', padding: '0 10px', flex: 3.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.favorite')}
          </Typography>
          <IconButton sx={{ width: '36px' }}>
            <FavoriteHeartIcon isFavorite={placement?.isFavorite} width={36} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.reliability')}
          </Typography>
          <Typography sx={{ display: 'flex', fontSize: '18px' }}>
            {placement?.reliabilityScore ? `${placement.reliabilityScore * 100}%` : '--'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.status')}
          </Typography>
          <PendingStatusMenuButton
            status={status}
            revokeOrAcceptText={revokeOrAcceptText}
            resendOrRejectText={resendOrRejectText}
            onRevokeOrAcceptInvite={onRevokeOrAcceptInvite}
            onResendOrRejectInvite={onResendOrRejectInvite}
          />
        </Box>
      </Box>
      <Box sx={{ flex: 0.5 }}>
        <IconButton onClick={onManageCandidate}>
          <MoreHorizIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

PendingEmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    id: PropTypes.string,
    employeeName: PropTypes.string,
    employeeProfilePicture: PropTypes.string,
    isFavorite: PropTypes.bool,
    reliabilityScore: PropTypes.number,
    status: PropTypes.string,
  }),
  isInvite: PropTypes.bool,
  onManageCandidate: PropTypes.func,
  onRevokeOrAcceptInvite: PropTypes.func,
  onResendOrRejectInvite: PropTypes.func,
};

export default PendingEmployeeRosterCard;
