import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';

import Calendar from '../../assets/icons/Calendar.svg';
import KeyboardArrowDownIcon from '../../assets/icons/KeyboardArrowDownIcon.svg';
import theme from '../../theme';

const DateDisplayBox = ({ data }) => (
  <Grid
    item
    container
    justifyContent="space-between"
    alignItems="center"
    sx={{
      height: '40px',
      width: 'auto',
      backgroundColor: theme?.dateRangePickerFilter?.dateDisplayBox?.bgColor,
      borderRadius: '40px',
      margin: '16px',
      marginBottom: 0,
      paddingRight: '16px',
      paddingLeft: '16px',
    }}
  >
    <Typography sx={theme?.dateRangePickerFilter?.dateDisplayBox?.typography}>{data}</Typography>
    <Box
      component="img"
      sx={{
        height: 24,
        width: 24,
      }}
      alt="Calendar"
      src={Calendar}
    />
  </Grid>
);

DateDisplayBox.propTypes = {
  data: PropTypes.string,
};

const DateRangePickerFilter = ({
  onValueChange,
  field,
  operation,
  placeholder,
  initialValue,
  wrapperSx,
}) => {
  const [value, setValue] = useState(initialValue || [(null, null)]);
  const [anchorEl, setAnchorEl] = useState(null);
  const initalDisplayValue = {
    start: (initialValue && new Date(initialValue[0]).toLocaleDateString('en-US')) || null,
    end: (initialValue && new Date(initialValue[1]).toLocaleDateString('en-US')) || null,
  };

  const [displayValue, setDisplayValue] = useState(initalDisplayValue || [(null, null)]);
  const dateRangeDisplayValue =
    (displayValue.start && displayValue.end && `${displayValue.start} to ${displayValue.end}`) ||
    (displayValue.start && `${displayValue.start}`) ||
    '';
  const OneDayInEpoch = 86400000;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleChange = (dateRange) => {
    setValue(dateRange);

    const dateInEpoch = dateRange.map((date, index) =>
      index === 0 ? { start: new Date(date).getTime() } : { end: new Date(date).getTime() }
    );
    const updatedDateRange = Object.assign({}, ...dateInEpoch);
    if (!(updatedDateRange.start && !updatedDateRange.end)) {
      const newValue = [
        {
          value: updatedDateRange.start ? updatedDateRange.start : null,
          field: field.start,
          operation: operation.start,
        },
        {
          value: updatedDateRange.end ? updatedDateRange.end + OneDayInEpoch : null,
          field: field.end,
          operation: operation.end,
        },
      ];
      onValueChange(newValue);
      const readableDateRange = dateRange.map((date, index) =>
        index === 0
          ? { start: date ? new Date(date).toLocaleDateString('en-US') : null }
          : { end: date ? new Date(date).toLocaleDateString('en-US') : null }
      );
      setDisplayValue(Object.assign({}, ...readableDateRange));
    }
  };
  const handleClick = (event) => {
    const currentTarget = event?.currentTarget;
    setAnchorEl(anchorEl ? null : currentTarget);
  };

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !displayValue.start && theme?.dateRangePickerFilter?.bgColor?.hover,
    },
    '&:focus-within': {
      backgroundColor: !displayValue.start && theme?.dateRangePickerFilter?.bgColor?.focus,
      border: `1px solid ${theme?.dateRangePickerFilter?.borderColor?.focus}`,
    },
    backgroundColor: displayValue.start && theme?.dateRangePickerFilter?.bgColor?.complete,

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '300px',
    marginLeft: '8px',
  };

  return (
    <>
      <TextField
        autoComplete="off"
        aria-describedby={id}
        sx={{ ...styleForFormControl, ...wrapperSx }}
        value={dateRangeDisplayValue}
        InputProps={{
          onClick: handleClick,
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
              <>
                {displayValue.start && (
                  <Chip
                    label={
                      (displayValue.start && displayValue.end && 2) ||
                      (displayValue.start && 1) ||
                      ''
                    }
                    sx={{
                      height: '22px',
                      width: '35px',
                      backgroundColor: theme?.dateRangePickerFilter?.chipBgColor,
                    }}
                  />
                )}
                {dateRangeDisplayValue && (
                  <IconButton size="small" onClick={() => handleChange([null, null])}>
                    <CloseIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
                )}
                <Box
                  component="img"
                  sx={{
                    height: 22,
                    width: 22,
                  }}
                  alt="Stars"
                  src={KeyboardArrowDownIcon}
                />
              </>
            </Box>
          ),
        }}
        placeholder={placeholder}
      />

      <Popper open={open} placement="bottom-end" anchorEl={anchorEl} id={id}>
        <ClickAwayListener onClickAway={handleClick}>
          <Paper>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Check-in' }}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                calendars={1}
                value={value}
                onChange={handleChange}
                renderInput={(startProps) => <TextField {...startProps} />}
              />
            </LocalizationProvider>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DateRangePickerFilter;

DateRangePickerFilter.propTypes = {
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  field: PropTypes.shape(),
  operation: PropTypes.shape(),
  initialValue: PropTypes.arrayOf(PropTypes.number),
  wrapperSx: PropTypes.shape({}),
};
