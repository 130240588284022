import React from 'react';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Avatar, Box, IconButton, Typography } from '@mui/material';

import { BLACK } from '../../../../../theme/colorConstants';

const InviteEmployeeRosterCard = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      background: 'white',
      justifyContent: 'space-between',
      padding: '10px',
      border: '1px dashed #D7BDFF',
    }}
  >
    <Box display="flex" alignItems="center">
      <Avatar />
      <Typography style={{ marginLeft: '10px', fontSize: '24px', color: BLACK[40] }}>
        {LanguageConverter('jobOrder.roster.inviteEmployee')}
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', padding: '0 10px' }}>
      <IconButton color="#7A23FF">
        <PersonAddAltIcon color="primary" />
      </IconButton>
    </Box>
  </Box>
);

export default InviteEmployeeRosterCard;
