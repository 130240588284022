import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { JOB_ORDER_TIME_STATUSES } from '../../views/jobOrders/jobOrderConstants';
import FlipCard from '../FlipCard';

import ActiveRosterView from './ActiveRosterView';
import CompletedRosterView from './CompletedRosterView';
import JobView from './JobView';
import UpcomingRosterView from './UpcomingRosterView';

const ShiftCard = ({
  jobOrder,
  index,
  date,
  moveCard,
  isFlipped,
  handleFlipClick,
  onPlacementCheckStatusUpdate,
  handleTimecardEditSubmit,
  onStatusUpdate,
  clearTimeCardError,
  timecardIsUpdating,
  timecardUpdateError,
  isCancelled,
  timeStatus,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <FlipCard
      frontContent={
        <JobView jobOrder={jobOrder} timeStatus={timeStatus} isCancelled={isCancelled} />
      }
      backContent={
        (timeStatus === JOB_ORDER_TIME_STATUSES.ACTIVE && (
          <ActiveRosterView
            isExpanded={isExpanded}
            jobOrder={jobOrder}
            onPlacementCheckStatusUpdate={onPlacementCheckStatusUpdate}
            clearTimeCardError={clearTimeCardError}
            handleTimecardEditSubmit={handleTimecardEditSubmit}
            onStatusUpdate={onStatusUpdate}
            timecardIsUpdating={timecardIsUpdating}
            timecardUpdateError={timecardUpdateError}
            timeStatus={timeStatus}
          />
        )) ||
        (timeStatus === JOB_ORDER_TIME_STATUSES.UPCOMING && (
          <UpcomingRosterView isExpanded={isExpanded} jobOrder={jobOrder} />
        )) ||
        (timeStatus === JOB_ORDER_TIME_STATUSES.COMPLETED && (
          <CompletedRosterView isExpanded={isExpanded} jobOrder={jobOrder} />
        ))
      }
      index={index}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      date={date}
      moveCard={moveCard}
      isFlipped={isFlipped}
      handleFlipClick={handleFlipClick}
      isCancelled={isCancelled}
      timeStatus={timeStatus}
    />
  );
};

ShiftCard.propTypes = {
  jobOrder: PropTypes.shape({
    placements: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        userName: PropTypes.string,
        current: PropTypes.shape({
          totalHours: PropTypes.string,
          punches: PropTypes.arrayOf(
            PropTypes.shape({
              clockIn: PropTypes.string,
              clockOut: PropTypes.string,
              hours: PropTypes.string,
            })
          ),
        }),
      })
    ),
    name: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  moveCard: PropTypes.func.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  handleFlipClick: PropTypes.func.isRequired,
  onPlacementCheckStatusUpdate: PropTypes.func.isRequired,
  clearTimeCardError: PropTypes.func.isRequired,
  handleTimecardEditSubmit: PropTypes.func.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
  timecardUpdateError: PropTypes.string,
  timecardIsUpdating: PropTypes.bool,
  isCancelled: PropTypes.bool.isRequired,
  timeStatus: PropTypes.string.isRequired,
};

export default ShiftCard;
