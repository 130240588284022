import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '../../../theme';
import { errorHandler } from '../formUtils';

import { getRadioButtonFieldError } from './helpers';
import {
  cardSpacing,
  cardWidthAndHeight,
  radioButtonStyling,
  radioCardDescriptionStyling,
  radioCardLabelStyling,
  radioLabelStyling,
  radioSpacing,
} from './styling';

const FormRadioButtons = (props) => {
  const {
    cardSx,
    direction = 'column', // row or column
    fieldErrorData,
    input,
    isAbleToUnselect, // allows radio buttons to be unselected
    isRadioCard, // if true, radio buttons are wrapped with a card
    labels,
    meta,
    radioButtonSx,
    required,
    size,
    sx,
    variant,
  } = props;
  const intl = useIntl();
  const { onChange } = input;

  const additionalValidators = errorHandler(input, meta, fieldErrorData);

  const largerThanMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const error =
    getRadioButtonFieldError({
      value: input?.value,
      required,
    }) || additionalValidators;

  const shouldShowError = error && (meta.submitFailed || meta.modified || meta.touched);

  const handleOnClick = (event) => {
    if (isAbleToUnselect && event?.target?.value === input?.value) {
      onChange('');
    } else {
      onChange(event?.target?.value);
    }
  };

  return (
    <FormControl error={!!shouldShowError} variant={variant}>
      <RadioGroup
        name={input.name}
        value={input.value}
        type="radio"
        row={direction === 'row'}
        sx={{ margin: theme.spacing(0, 0, 0, 0.5), ...sx }}
      >
        {labels &&
          labels.map((label) => {
            const value = label?.value || label?.name;
            const name = label?.name;
            const disabled = label?.disabled || false;
            return isRadioCard ? (
              <Card
                sx={{
                  ...cardWidthAndHeight(largerThanMediumScreen),
                  boxShadow: 3,
                  borderRadius: '8px',
                  ...cardSpacing(labels.length, direction, largerThanMediumScreen),

                  ...cardSx,
                }}
                key={name + value}
              >
                <CardContent>
                  <FormControlLabel
                    control={
                      <Radio
                        required={required}
                        onClick={handleOnClick}
                        sx={{ ...radioButtonStyling, ...radioButtonSx }}
                      />
                    }
                    key={name + value}
                    label={name}
                    size={size}
                    value={value}
                    disabled={disabled}
                    sx={radioCardLabelStyling}
                  />
                  <Box sx={radioCardDescriptionStyling}>{label?.radioCardDescription || ''}</Box>
                </CardContent>
              </Card>
            ) : (
              <FormControlLabel
                control={
                  <Radio
                    required={required}
                    onClick={handleOnClick}
                    sx={{ ...radioButtonStyling, ...radioButtonSx }}
                  />
                }
                key={name + value}
                label={name}
                size={size}
                value={value}
                disabled={disabled}
                sx={{
                  ...radioSpacing(labels.length, direction, largerThanMediumScreen),
                  ...radioLabelStyling,
                }}
              />
            );
          })}
      </RadioGroup>
      <FormHelperText>{shouldShowError ? intl.formatMessage({ id: error }) : null}</FormHelperText>
    </FormControl>
  );
};

FormRadioButtons.propTypes = {
  cardSx: PropTypes.shape({}),
  direction: PropTypes.oneOf(['row', 'column']).isRequired,
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.shape({})]),
  }),
  isAbleToUnselect: PropTypes.bool,
  isRadioCard: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        // value should be anything, please add any missing types if missing
        PropTypes.string,
        PropTypes.array,
        PropTypes.shape({}),
        PropTypes.number,
        PropTypes.bool,
      ]),
      radioCardDescription: PropTypes.string,
    })
  ),
  meta: PropTypes.shape(),
  radioButtonSx: PropTypes.shape({}),
  required: PropTypes.bool,
  size: PropTypes.string, // default medium
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default FormRadioButtons;
