import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import CalendarIcon from '../../../../../assets/icons/CalendarIcon';
import FavoriteHeartIcon from '../../../../../assets/icons/FavoriteHeartIcon';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';

import DeletePlacementMenuButton from './DeletePlacementMenuButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #E0E0E0',
  },
  propText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#7A7A7A',
  },
});

const ActiveEmployeeRosterCard = ({
  placement,
  isOngoingStatus,
  onManageCandidate,
  onDeleteFromOne,
  onDeleteFromAll,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" flex={7}>
        <Avatar src={placement?.employeeProfilePicture} alt={placement.employeeName} />
        <Typography style={{ marginLeft: '10px', fontSize: '24px' }}>
          {placement?.employeeName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '24px', padding: '0 10px', flex: 3.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.favorite')}
          </Typography>
          <IconButton sx={{ width: '36px' }}>
            <FavoriteHeartIcon isFavorite={placement?.isFavorite} width={36} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.reliability')}
          </Typography>
          <Typography
            sx={{ display: 'flex', fontSize: '18px', height: '100%', alignItems: 'center' }}
          >
            {placement?.reliabilityScore ? `${placement.reliabilityScore * 100}%` : '--'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.status')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <CalendarIcon
                isEventRepeat={isOngoingStatus}
                width={isOngoingStatus ? 23 : 18}
                height={isOngoingStatus ? 24 : 20}
              />
              <Typography fontSize="14px" marginLeft="12px" fontFamily={PRIMARY_FONT[500]}>
                {isOngoingStatus
                  ? LanguageConverter('jobOrder.roster.status.ongoingEmployee')
                  : LanguageConverter('jobOrder.roster.status.backfill')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flex: 0.5, flexDirection: 'row', gap: '12px' }}>
        <IconButton onClick={onManageCandidate}>
          <MoreHorizIcon />
        </IconButton>
        <DeletePlacementMenuButton
          onDeleteFromOne={onDeleteFromOne}
          onDeleteFromAll={onDeleteFromAll}
        />
      </Box>
    </Box>
  );
};

ActiveEmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    id: PropTypes.string,
    employeeName: PropTypes.string,
    employeeProfilePicture: PropTypes.string,
    isFavorite: PropTypes.bool,
    cancelledDate: PropTypes.number,
    cancelledWithin24Hours: PropTypes.bool,
    reliabilityScore: PropTypes.number,
    status: PropTypes.string,
    placementId: PropTypes.string,
  }),
  isOngoingStatus: PropTypes.bool,
  onManageCandidate: PropTypes.func,
  onDeleteFromOne: PropTypes.func,
  onDeleteFromAll: PropTypes.func,
};

export default ActiveEmployeeRosterCard;
