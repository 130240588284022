import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DatePicker, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';

import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme';
import { getEndDateFieldError, getStartFieldError, ONGOING_KEY } from '../../../helpers';

import JobRangeActionBar from './JobRangeActionBar';

const RANGE_POSITIONS = {
  START: 'start',
  END: 'end',
};

const JobRangeForForm = ({ input, meta }) => {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const form = useForm();

  const [isOngoing, setIsOngoing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [rangePosition, setRangePosition] = useState(null);

  // For when job has end date- still set as array for consistency
  const rangeChangeHandler = (change) => {
    const newValue = rangePosition === RANGE_POSITIONS.END ? [input?.value[0], change[0]] : change;
    input.onChange(newValue);
    form.blur('dateRange', true);
    if (rangePosition === RANGE_POSITIONS.END) {
      setRangePosition(null);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setRangePosition(false);
  }, [isOpen]);

  // For when no end date- still set as array for consistency
  const dateChangeHandler = (change) => {
    input.onChange([change, ONGOING_KEY]);
  };

  const ongoingSelectionHandler = () => {
    if (isOngoing) {
      input.onChange([(input?.value && input?.value[0]) || null, null]);
      setRangePosition(RANGE_POSITIONS.END);
    } else {
      input.onChange([(input?.value && input?.value[0]) || null, ONGOING_KEY]);
      setIsOpen(false);
    }
    setIsOngoing(!isOngoing);
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <CalendarTodayOutlinedIcon />
      </InputAdornment>
    ),
  };

  const startError = getStartFieldError({ value: input?.value });
  const endError = getEndDateFieldError({ value: input?.value });

  const shouldShowStartError = startError && (meta.submitFailed || meta.modified || meta.touched);
  const shouldShowEndError = endError && (meta.submitFailed || meta.modified || meta.touched);

  const commonPickerProps = {
    onOpen: () => setIsOpen(true),
    onClose: () => setIsOpen(false),
    disablePast: !user?.role === UserRole.ADMIN,
    calendars: 1,
    open: isOpen,
    components: {
      ActionBar: JobRangeActionBar,
    },
    componentsProps: {
      actionBar: {
        input: {
          value: isOngoing,
          onChange: ongoingSelectionHandler,
        },
      },
    },
    PopperProps: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom'],
          },
        },
      ],
    },
  };

  const commonTextFieldProps = {
    variant: 'filled',
    InputProps: inputProps,
    onClick: () => setIsOpen(true),
    sx: {
      '& .MuiFilledInput-root': {
        borderBottom: 'none',
        marginRight: theme.spacing(1),
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiInputLabel-root': {
        color: theme?.textField?.inputLabel?.outlined?.focused,
      },
    },
  };

  const startDateLabel = intl.formatMessage({ id: 'job.create.startDate' });
  const endDateLabel = intl.formatMessage({ id: 'job.create.endDate' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{ start: startDateLabel, end: endDateLabel }}
    >
      {isOngoing ? (
        <DatePicker
          {...commonPickerProps}
          value={(input?.value && input?.value[0]) || {}}
          onChange={dateChangeHandler}
          renderInput={(props) => (
            <>
              <TextField
                {...props}
                {...commonTextFieldProps}
                label={startDateLabel}
                error={!!shouldShowStartError}
                helperText={shouldShowStartError && intl.formatMessage({ id: startError })}
              />
              <TextField
                {...commonTextFieldProps}
                readOnly
                value={ONGOING_KEY}
                label={endDateLabel}
                error={!!shouldShowEndError}
                helperText={shouldShowEndError && intl.formatMessage({ id: endError })}
              />
            </>
          )}
        />
      ) : (
        <DateRangePicker
          {...commonPickerProps}
          value={input?.value || [null, null]}
          onChange={rangeChangeHandler}
          rangePosition="end"
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                {...commonTextFieldProps}
                error={!!shouldShowStartError}
                helperText={shouldShowStartError && intl.formatMessage({ id: startError })}
              />
              <TextField
                {...endProps}
                {...commonTextFieldProps}
                error={!!shouldShowEndError}
                helperText={shouldShowEndError && intl.formatMessage({ id: endError })}
              />
            </>
          )}
        />
      )}
    </LocalizationProvider>
  );
};

JobRangeForForm.propTypes = {
  createJobValues: PropTypes.shape({
    shift: PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
  }),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  }),
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    error: PropTypes.string,
    modified: PropTypes.bool,
    touched: PropTypes.bool,
  }),
};
export default JobRangeForForm;
