import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import asyncService from '../../../datahub/asyncService';
import { validateSchema } from '../../../main-deprecated/components/Form/validations';
import selectUser from '../../../main-deprecated/store/selectors/appSelector';
import KeyboardArrowDownIcon from '../../assets/icons/KeyboardArrowDownIcon.svg';
import Search from '../../assets/icons/Search.svg';
import theme from '../../theme';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SearchableSelectDropdownFilter = ({
  disabled,
  field,
  conditionalSearchTerm,
  operation,
  placeholder,
  optionsAPICallback,
  onValueChange,
  getOptionLabel,
  background,
  sx,
  initialValue,
  wrapperSx,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(initialValue || null);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = user?.token;
  const refreshToken = user?.refreshToken;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(open && searchTerm === '' && options.length === 0);

  switch (background) {
    case SEARCHBAR_BACKGROUND.DARK:
      // Dark mode style here
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      // Light mode style here
      break;
    default:
      // Default mode style here
      break;
  }

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !value && theme?.searchBoxForFilter?.bgColor?.hover,
    },
    '&:focus-within': {
      backgroundColor: !value && theme?.searchBoxForFilter?.bgColor?.focus,
      border: `1px solid ${theme?.searchBoxForFilter?.borderColor?.focus}`,
    },
    backgroundColor: value && theme?.searchBoxForFilter?.bgColor?.complete,

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '200px',
    marginLeft: '8px',
  };
  const radioSx = {
    height: 22,
    width: 22,
    color: theme?.button?.palette?.primary,
  };
  useEffect(() => {
    // this is to clean value of conditional filter in every api call (api call changes options)
    if (value) {
      setValue(null);
      onValueChange([{ value: null, field, operation }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionalSearchTerm]);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (!disabled) {
        const { contract, httpMethod, generateBody, route } = optionsAPICallback;

        const requestBody = generateBody(searchTerm, conditionalSearchTerm);
        if (contract && validateSchema(requestBody, contract)?.FORM_ERROR) {
          return;
        }

        asyncService({
          httpMethod,
          route,
          data: requestBody,
          onSuccess: (d) => {
            const apiResultDocuments = d?.data?.documents;
            setOptions(apiResultDocuments);
            setLoading(false);
          },
          onError: () => {
            setOptions([]);
            setLoading(false);
          },
          dispatch,
          token,
          refreshToken,
        });
      }
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [
    conditionalSearchTerm,
    searchTerm,
    dispatch,
    token,
    optionsAPICallback,
    loading,
    disabled,
    refreshToken,
  ]);

  const handleChange = (event, newValue) => {
    onValueChange([{ value: newValue ? newValue._id : null, field, operation }]);
    setValue(newValue);
  };

  return (
    <FormControl sx={{ ...styleForFormControl, ...wrapperSx }}>
      <Autocomplete
        onChange={handleChange}
        value={value}
        disabled={disabled}
        popupIcon={
          <Box
            component="img"
            sx={{
              height: 22,
              width: 22,
            }}
            alt="Stars"
            src={KeyboardArrowDownIcon}
          />
        }
        noOptionsText="No results found. Try searching for something else."
        loadingText={
          <Grid container justifyContent="space-between">
            <div> Loading results...</div>
            <CircularProgress size={22} />
          </Grid>
        }
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {getOptionLabel(option)}
            {selected ? (
              <RadioButtonCheckedIcon sx={radioSx} />
            ) : (
              <RadioButtonUncheckedIcon sx={radioSx} />
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={sx}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: open && (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 24,
                      width: 24,
                    }}
                    alt="Stars"
                    src={Search}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
                  {value && (
                    <Chip
                      label="1"
                      sx={{
                        height: '22px',
                        width: '35px',
                        backgroundColor: theme?.searchBoxForFilter?.chipBgColor,
                      }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </Box>
              ),
            }}
            onChange={(e) => {
              setOptions([]);
              setLoading(true);
              setSearchTerm(e.target.value);
            }}
            onBlur={() => {
              setSearchTerm('');
              setOptions([]);
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option._id === val._id}
        open={open}
        onOpen={() => {
          setLoading(options.length <= 0);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        loading={loading}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SearchableSelectDropdownFilter.propTypes = {
  field: PropTypes.string,
  disabled: PropTypes.bool,
  conditionalSearchTerm: PropTypes.string,
  operation: PropTypes.string,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  optionsAPICallback: PropTypes.shape({
    httpMethod: PropTypes.string,
    route: PropTypes.string,
    generateBody: PropTypes.func,
    contract: PropTypes.shape({}),
  }),
  getOptionLabel: PropTypes.func,
  sx: PropTypes.shape({}),
  background: PropTypes.string,
  initialValue: PropTypes.oneOf([PropTypes.shape({}), PropTypes.string]),
  wrapperSx: PropTypes.shape({}),
};

export { SearchableSelectDropdownFilter, SEARCHBAR_BACKGROUND };
