import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { IconButton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import FavoriteHeartIcon from '../../../../../assets/icons/FavoriteHeartIcon';
import YellowAlert from '../../../../../assets/icons/YellowAlert';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../../../utils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #E0E0E0',
  },
  propText: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#7A7A7A',
  },
});

const CancelledEmployeeRosterCard = ({ placement, onManageCandidate }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" flex={7}>
        <Avatar src={placement?.employeeProfilePicture} alt={placement.employeeName} />
        <Typography style={{ marginLeft: '10px', fontSize: '24px' }}>
          {placement?.employeeName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '24px', padding: '0 10px', flex: 3.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.favorite')}
          </Typography>
          <IconButton sx={{ width: '36px' }}>
            <FavoriteHeartIcon isFavorite={placement?.isFavorite} width={36} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.reliability')}
          </Typography>
          <Typography sx={{ display: 'flex', fontSize: '18px' }}>
            {placement?.reliabilityScore ? `${placement.reliabilityScore * 100}%` : '--'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className={classes.propText}>
            {LanguageConverter('jobOrder.roster.cancellationTime')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <YellowAlert />
            <Typography sx={{ display: 'flex', fontSize: '18px' }}>
              {placement?.cancelledDate
                ? `${epochToDateInReadableFormat(
                    placement.cancelledDate
                  )} at ${epochToTimeInReadableFormat(placement.cancelledDate)}`
                : '--'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box flex={1}>
        <Typography
          sx={{
            alignContent: 'center',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: PRIMARY_FONT[400],
          }}
          onClick={onManageCandidate}
        >
          {LanguageConverter('jobOrder.roster.viewMore')}
        </Typography>
      </Box>
    </Box>
  );
};

CancelledEmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    id: PropTypes.string,
    employeeName: PropTypes.string,
    employeeProfilePicture: PropTypes.string,
    isFavorite: PropTypes.bool,
    cancelledDate: PropTypes.number,
    cancelledWithin24Hours: PropTypes.bool,
    reliabilityScore: PropTypes.number,
    status: PropTypes.string,
  }),
  onManageCandidate: PropTypes.func,
};

export default CancelledEmployeeRosterCard;
