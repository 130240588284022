import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';

const activeColor = `${theme?.components?.navigationBar?.palette?.activeColor}`;
const activeFont = `${theme?.components?.navigationBar?.typography?.active}`;
const inactiveColor = `${theme?.components?.navigationBar?.palette?.inactiveColor}`;
const inactiveFont = `${theme?.components?.navigationBar?.typography?.inactive}`;

const NavigationTab = ({ url, Icon, label, id }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === `/${url}`) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location, url]);

  const handleOnClick = () => {
    if (url) {
      navigate(url);
    } else {
      setActive(true);
    }
  };

  return (
    <Button
      size="medium"
      startIcon={
        <Icon
          sx={{
            color: active ? activeColor : inactiveColor,
          }}
        />
      }
      onClick={handleOnClick}
      sx={{
        borderRadius: '25px',
        marginLeft: '0',
        textTransform: 'capitalize',
      }}
      data-testid={id}
    >
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: active ? activeColor : inactiveColor,
          fontFamily: active ? activeFont : inactiveFont,
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

NavigationTab.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  Icon: PropTypes.shape({}),
  id: PropTypes.string,
};

export default NavigationTab;
