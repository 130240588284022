import { uniq } from 'lodash/fp';

import { ONE_MINUTE_IN_MS } from '@careerstart/wae-common/src/main/constants/timeConversions';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import { getTimeZone, minutesFromMidnightToReadableTime } from '../../utils';

import { BACKFILL_VALUES } from './constDicts';

export const ONGOING_KEY = 'Ongoing';

/**
 *
 * @param {Object} daysOfWeek {dayOfWeek: boolean}
 * @returns {Array<string>} Returns a list of strings
 */
export const getCadence = (daysOfWeek) =>
  Object.keys(daysOfWeek)
    .filter((day) => daysOfWeek[day])
    .map((day) => day.toLowerCase());

export const customShift = {
  name: 'customShift',
};

export const getAllValidShiftTimes = () => {
  const returnArray = [];

  const numberOf5MinOccurances = (24 * 60) / 5 - 1; // 24 hours * 60 minutes / 5 minutes - last occurance
  for (let i = 0; i <= numberOf5MinOccurances; i += 1) {
    returnArray.push({
      value: i * 5,
      displayLabel: minutesFromMidnightToReadableTime(i * 5),
      _id: minutesFromMidnightToReadableTime(i * 5),
      key: i * 5,
    });
  }

  return returnArray;
};

export const getExtensionGroupPayload = ({ formValues, user }) => {
  const positionTemplateID = formValues?.positionTemplate?._id;
  const locationID = formValues?.location?._id;
  const departmentID = formValues?.department?._id;
  const numOpenings = parseInt(formValues?.numOpenings, 10);
  const numOverstaff = parseInt(formValues?.numOverstaff, 10) || 0;

  const requireEmployerApproval = !!(formValues?.requireEmployerApproval || false);

  // might be / SHOULD probably be two fields in new form. One start, one end
  const shift = {
    start: formValues?.startTime?.value,
    end: formValues?.endTime?.value,
    zone: getTimeZone(),
  };

  const candidates = formValues?.candidates?.map((item) => item.id) || [];

  const favCandidates = formValues?.inviteEmployeeFavoriteList?.length
    ? formValues.inviteEmployeeFavoriteList.map((e) => e.waeID)
    : [];
  const cadence = getCadence(formValues?.daysOfWeek);

  const startDateValue = formValues?.dateRange[0] || null;
  const endDateValue = formValues?.dateRange[1] || null;

  const isOngoing = endDateValue === ONGOING_KEY;

  const startDate = new Date(startDateValue);
  const startDateEpoch = startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(endDateValue);
  const endDateEpoch = endDate.setHours(0, 0, 0, 0) + shift.end * ONE_MINUTE_IN_MS;

  const allowBackfill = formValues?.allowBackfill === BACKFILL_VALUES.ALLOW;

  const group = {
    cadence,
    start: startDateEpoch,
    end: isOngoing ? undefined : endDateEpoch,
    jobOrderInfo: {
      department: departmentID,
      location: locationID,
      numOpenings,
      ...(user?.role === UserRole.ADMIN ? { numOverstaff } : {}),
      positionTemplate: positionTemplateID,
      shift,
      requireEmployerApproval,
      allowBackfill,
    },
    candidates: uniq([...candidates, ...favCandidates]),
  };

  return {
    extensionGroups: [group],
  };
};

export const getEndDateFieldError = ({ value }) => {
  if (!value) {
    return 'error.field.empty';
  }

  const isOngoing = value[1] === ONGOING_KEY;
  const startEpoch = new Date(value[0]).getTime();
  const endEpoch = new Date(value[1]).getTime();

  // Date is not valid - cannot convert to epoch
  if (!endEpoch && !isOngoing) {
    return 'error.date.invalid';
  }

  if (!isOngoing && endEpoch < startEpoch) {
    return 'error.dates.inverted';
  }

  return undefined;
};

export const getStartFieldError = ({ value }) => {
  if (!value) {
    return 'error.field.empty';
  }

  const startEpoch = new Date(value[0]).getTime();

  // Date is not valid - cannot convert to epoch
  if (!startEpoch) {
    return 'error.date.invalid';
  }

  return undefined;
};

export const isValidDateRange = (value) =>
  getStartFieldError({ value }) || getStartFieldError({ value });

const getShiftLengthInMinutes = ({ start, end }) => {
  let duration; // Calculate the duration based on whether endTime is before or after startTime
  const minutesInDay = 24 * 60;
  if (end >= start) {
    // If end is after or at start, the duration is straightforward
    duration = end - start;
  } else {
    // If end is before start, it means the range spans over midnight
    duration = minutesInDay - start + end;
  }
  return duration;
};

export const shiftTimeValidation = (val, formVals) => {
  if (!val) {
    return 'error.field.empty';
  }

  const maxDurationMinutes = 20 * 60;

  const duration = getShiftLengthInMinutes({
    start: formVals?.startTime?.value,
    end: formVals?.endTime?.value,
  });

  // Check if the duration is less than 20 hours
  if (duration > maxDurationMinutes || duration === 0) {
    return 'error.jobCreate.shiftLength';
  }
  return null;
};

export const isWeekOT = (formVals) => {
  const cad = getCadence(formVals?.daysOfWeek || {});
  const shiftMins = getShiftLengthInMinutes({
    start: formVals?.startTime?.value,
    end: formVals?.endTime?.value,
  });

  const otMarkerInMinutes = 40 * 60; // 40hrs * 60min
  if (shiftMins * cad.length > otMarkerInMinutes) {
    return true;
  }
  return false;
};
