import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { epochToDateInReadableFormat } from '../../../../utils';

const useStyles = makeStyles({
  fillRatioWrapper: { width: '250px', display: 'flex', alignItems: 'center' },
  fillRatioInner: { minWidth: 35 },
  fillRatioText: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: '5px',
  },
  fillRatioProgressWrap: { width: '100%' },

  jobInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& .block': {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '& .title': {
        color: 'gray',
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '8px',
        '& .twoColumn': {
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
        },
      },
      '& .data': {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'left',
        '& .twoColumn': {
          display: 'flex',
          flexDirection: 'row',
          gap: '14px',
          '&.times': {
            gap: '8px',
          },
          '&.staffing': {
            gap: '64px',
          },
        },
      },
    },
  },
});

const CancelledJobMetrics = ({ jobOrder }) => {
  const classes = useStyles();

  return (
    <div className={classes.jobInfo}>
      <Box className="block">
        <Box className="title"> {LanguageConverter('roster.form.openings')} </Box>
        <Box className={classes.fillRatioWrapper}>
          <Box className="twoColumn staffing">
            <Box> {jobOrder.numOpenings} </Box>
          </Box>
        </Box>
      </Box>
      <Box className="block">
        <Box className="title">
          <Box className="twoColumn">
            <Box> {LanguageConverter('roster.form.shiftCancelledOn')} </Box>
            <Box> {LanguageConverter('roster.form.shiftCancelledBy')} </Box>
          </Box>
        </Box>
        <Box className="data">
          <Box className="twoColumn staffing">
            <Box>{epochToDateInReadableFormat(jobOrder?.cancellation?.time)} </Box>
            <Box> {jobOrder?.cancellation?.user?.name} </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

CancelledJobMetrics.propTypes = {
  jobOrder: PropTypes.shape({
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
    cancellation: PropTypes.shape({
      time: PropTypes.number,
      user: PropTypes.shape({ name: PropTypes.string }),
    }),
  }),
};

export default CancelledJobMetrics;
