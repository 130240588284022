import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TickIcon from '../../assets/icons/TickIcon';
import theme from '../../theme';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    boxShadow: 'none',
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      justifyContent: 'space-between',
      '& .header': {
        display: 'flex',
        '& .avatar': {
          marginRight: '10px',
          width: '34px',
          height: '34px',
        },
        '& .employee': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .name': {
            display: 'flex',
            fontSize: '16px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.employeeName,
          },
        },
      },
    },
  },

  checkMenu: { width: '50px' },
});

const EmployeeReserveListCard = ({ profilePic, fullName }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className="content">
        <Box className="header">
          <Avatar src={profilePic} alt={fullName} className="avatar" />
          <Box className="employee">
            <Typography variant="h6" className="name">
              {fullName}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.checkMenu}>
          <IconButton size="small">
            <TickIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

EmployeeReserveListCard.propTypes = {
  profilePic: PropTypes.string,
  fullName: PropTypes.string,
};

export default EmployeeReserveListCard;
