export const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const getColors = ({ theme, background }) => {
  let searchBackgroundColor;
  let textFieldBackground;
  let textFieldPrimaryLabel;
  let textFieldFocusedBorder;
  let textFieldBackgroundHover;
  let textFieldErrorBorder;

  switch (background) {
    case SEARCHBAR_BACKGROUND.DARK:
      searchBackgroundColor = theme?.V2?.bgColor?.darkBkColor;
      textFieldBackground = theme?.searchBox?.textBox?.darkBkColor;
      textFieldBackgroundHover = theme?.palette?.secondary?.light;
      textFieldFocusedBorder = theme?.textField?.borderColor?.focused;
      textFieldErrorBorder = theme?.textField?.borderColor?.error;
      textFieldPrimaryLabel = theme?.textField?.inputLabel?.outlined?.light;
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      searchBackgroundColor = theme?.searchBox?.bgColor?.lightBkColor;
      textFieldBackground = theme?.textField?.inputLabel?.primary?.light;
      textFieldBackgroundHover = theme?.textField?.background?.light;
      textFieldFocusedBorder = theme?.textField?.borderColor?.focused;
      textFieldErrorBorder = theme?.textField?.borderColor?.error;
      textFieldPrimaryLabel = theme?.textField?.inputLabel?.outlined?.focused;
      break;
    default:
      searchBackgroundColor = theme?.searchBox?.bgColor?.lightBkColor;
      textFieldBackground = theme?.textfield?.inputLabel?.primary?.light;
      textFieldBackgroundHover = theme?.textField?.background?.light;
      textFieldFocusedBorder = theme?.textField?.borderColor?.focused;
      textFieldErrorBorder = theme?.textField?.borderColor?.error;
      textFieldPrimaryLabel = theme?.textField?.inputLabel?.outlined?.focused;
      break;
  }

  return {
    searchBackgroundColor,
    textFieldBackground,
    textFieldPrimaryLabel,
    textFieldFocusedBorder,
    textFieldBackgroundHover,
    textFieldErrorBorder,
  };
};

export const getStyles = ({ theme, background, sx, disabled }) => {
  const {
    searchBackgroundColor,
    textFieldBackground,
    textFieldPrimaryLabel,
    textFieldFocusedBorder,
    textFieldBackgroundHover,
    textFieldErrorBorder,
  } = getColors({ theme, background });

  const styleForTextField = {
    ...sx,
    '& .MuiOutlinedInput-root': {
      height: '40px',
      background: textFieldBackground,
      padding: theme.spacing(0, 1),
      fontFamily: 'Barlow',
      '&:hover': {
        background: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: `2px solid ${textFieldFocusedBorder}`,
      background: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      border: `2px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    paddingBottom: '16px',
  };

  const styleForGrid = {
    backgroundColor: searchBackgroundColor,
  };

  const styleForIcon = {
    color: disabled
      ? theme?.textField?.inputLabel?.iconColor?.disabled
      : theme?.textField?.inputLabel?.iconColor?.enabled,
  };

  const styleForDropDownPaper = {
    padding: theme.spacing(2),
    backgroundColor: searchBackgroundColor,
  };

  return {
    styleForTextField,
    styleForGrid,
    styleForIcon,
    styleForDropDownPaper,
  };
};
