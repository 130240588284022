import React from 'react';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const JobCreationFAQ = () => {
  const header = 'FAQ';
  const theme = useTheme();

  return (
    <Box sx={{ border: '1px solid #bbb', padding: theme.spacing(2), height: '75vh' }}>
      <Typography>{header}</Typography>

      <Box>
        Qui est veniam consequat esse enim id reprehenderit quis laboris deserunt ut occaecat
        incididunt. Labore laboris consectetur excepteur ullamco id laborum ea cupidatat
        adipisicing. Ea aliquip minim eu dolor cupidatat nostrud occaecat cupidatat quis ex eiusmod
        sit. Officia consequat excepteur ut amet sunt quis amet. Sint irure incididunt nostrud
        officia fugiat ipsum. Commodo voluptate cupidatat quis nulla pariatur. Eu elit nostrud esse
        sunt ea amet enim ipsum sunt Lorem aliqua ut.
      </Box>
    </Box>
  );
};

export default JobCreationFAQ;
