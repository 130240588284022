import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';

import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Button, { BUTTON_TYPE } from '../../../components/Button';

import Applications from './FormSections/Applications';
import BackFill from './FormSections/Backfill';
import Cadence from './FormSections/Cadence';
import Dates from './FormSections/Dates';
import InviteEmployees from './FormSections/InviteEmployees';
import LetsGetStarted from './FormSections/LetsGetStarted';
import Openings from './FormSections/Openings';
import ShiftTime from './FormSections/ShiftTime';
import ConfirmModal from './ConfirmModal';

const CreateJobFormBody = ({ handleSubmit, user }) => {
  const form = useForm();
  const formState = useFormState();
  const theme = useTheme();
  const intl = useIntl();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);

  const currentFormValues = formState.values;

  const sectionWrapperSx = {
    marginBottom: theme.spacing(4),
  };

  const reviewButtonOnClick = () => {
    setHasClickedSubmit(true);
    const fieldsWithErrors = Object.keys(formState.errors);

    // Marks all as touched to display errors
    if (fieldsWithErrors.length > 0) {
      form.batch(() => {
        fieldsWithErrors.forEach((fieldName) => {
          form.blur(fieldName, true);
        });
      });
    } else {
      setIsConfirmOpen(true);
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const [oldFormLength, setOldFormLength] = useState(0);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  useEffect(() => {
    const fieldsWithErrors = Object.keys(formState.errors);
    const fieldsWithValues = Object.keys(formState.values);

    if (
      fieldsWithValues.length > oldFormLength &&
      fieldsWithErrors.length === 0 &&
      activeStep < 7
    ) {
      setOldFormLength(fieldsWithValues.length);
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, formState.errors, formState.values, oldFormLength]);

  useEffect(() => {
    setHasClickedSubmit(false);
  }, [formState.values]);

  const sections = [
    {
      name: 'letsGetStarted',
      Section: LetsGetStarted,
    },
    {
      name: 'dates',
      Section: Dates,
    },
    {
      name: 'cadence',
      Section: Cadence,
    },
    {
      name: 'shiftTime',
      Section: ShiftTime,
    },
    {
      name: 'openings',
      Section: Openings,
    },
    currentFormValues?.positionTemplate?.allowEmployerApproval && {
      name: 'applications',
      Section: Applications,
    },
    {
      name: 'backfill',
      Section: BackFill,
    },
    {
      name: 'inviteEmployees',
      Section: InviteEmployees,
    },
  ].filter(Boolean);

  return (
    <Grid sx={{ height: '100%', padding: theme.spacing(1) }}>
      <form
        onSubmit={handleSubmit}
        style={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            minHeight: '350px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {sections.slice(0, activeStep + 1).map((sectionData) => (
            <sectionData.Section
              wrapperSx={sectionWrapperSx}
              user={user}
              willInviteEmployees={currentFormValues?.willInviteEmployees}
              key={sectionData?.name}
              isInviteModalOpen={isInviteModalOpen}
              setIsInviteModalOpen={setIsInviteModalOpen}
            />
          ))}
        </Box>
        <Grid container item justifyContent="flex-end" sx={{ width: '100%' }}>
          <Button
            data-testid="modalSubmitButton"
            type={BUTTON_TYPE.BUTTON}
            text={intl.formatMessage({ id: 'job.create.review' })}
            onClick={reviewButtonOnClick}
            disabled={
              (hasClickedSubmit && Object.keys(formState.errors).length > 0) || isInviteModalOpen
            }
          />
        </Grid>
        {activeStep > 6 && (
          <ConfirmModal
            isOpen={isConfirmOpen}
            onSubmit={handleSubmit}
            onClose={() => setIsConfirmOpen(false)}
          />
        )}
      </form>
    </Grid>
  );
};

CreateJobFormBody.propTypes = {
  handleSubmit: PropTypes.func,
  user: PropTypes.shape({
    role: PropTypes.string,
    employer: PropTypes.shape({
      corporation: PropTypes.string,
    }),
  }),
};

export default CreateJobFormBody;
